<template>
  <div>
    <div class="login-box">
      <div class="logo">
        <img src="@/assets/image/logo.png" alt="" />
      </div>

      <div class="form">
        <div class="form-title">账户信息</div>
        <el-form ref="formData" :model="formData" :rules="formRules" label-width="100px" :inline-message="true">
          <el-form-item label="账号类型" prop="userRole" v-if="false">
            <el-radio-group v-model="formData.userRole">
              <el-radio label="BUYER">试客</el-radio>
              <el-radio label="SELLER">商家</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="会员名" prop="username">
            <el-input class="form-input" v-model.trim="formData.username" size="small"></el-input>
          </el-form-item>

          <el-form-item label="登录密码" prop="password">
            <el-input class="form-input" v-model.trim="formData.password" size="small" type="password"></el-input>
          </el-form-item>

          <el-form-item label="重复输入" prop="password2">
            <el-input class="form-input" v-model.trim="formData.password2" size="small" type="password"></el-input>
          </el-form-item>

          <div class="form-title">账户信息</div>

          <el-form-item label="真实姓名" prop="name">
            <el-input class="form-input" v-model.trim="formData.name" size="small"></el-input>
          </el-form-item>

          <el-form-item label="所在地区" prop="address">
            <el-cascader
              class="form-input"
              size="small"
              v-model="formData.address"
              :options="regionOptions"
              @change="regionChange"
            ></el-cascader>
          </el-form-item>

          <!-- <el-form-item label="电子邮箱" prop="email">
            <el-input class="form-input" v-model="formData.email" size="small"></el-input>
          </el-form-item> -->

          <el-form-item label="手机号码" prop="mobile">
            <el-input class="form-input" v-model.trim="formData.mobile" size="small"></el-input>
          </el-form-item>

          <el-form-item label="手机验证码" prop="mobileCode" v-if="systemConfig.REG_SWITCH==1">
            <el-input class="form-input-sms" v-model.trim="formData.mobileCode" size="small"></el-input>
            <el-button class="form-input-sms-btn" type="primary" @click="sendSms" size="small" :disabled="smsSent">{{
              smsSent ? smsSendTimer + "秒后重发" : "立即发送"
            }}</el-button>
          </el-form-item>

          <el-form-item label="QQ号码" prop="qq">
            <el-input class="form-input" v-model.trim="formData.qq" size="small"></el-input>
          </el-form-item>

          <el-form-item label="微信号" prop="wechat">
            <el-input class="form-input" v-model.trim="formData.wechat" size="small"></el-input>
          </el-form-item>

          <el-form-item label="推荐人ID" prop="pid">
            <el-input class="form-input" v-model.trim="formData.pid" size="small"></el-input>
          </el-form-item>

          <el-form-item prop="userRuleCheck">
            <el-checkbox v-model="userRuleCheck">
              <router-link to="/agreement" style="text-decoration: none; color: inherit">《用户协议》</router-link>
            </el-checkbox>
          </el-form-item>

          <el-form-item>
            <el-button class="form-btn" type="primary" @click="formSubmit('formData')" size="small">注册</el-button>

            <router-link style="margin-left: 2rem; text-decoration: none; color: inherit" to="/login"
              >已有账号，去登录 ></router-link
            >
          </el-form-item>
        </el-form>
      </div>

      <div v-if="!isMobile && showSidebar" class="fixed right-10 top-50 bg-gray-50 text-blue-400 text-12px flex flex-col">
        <!-- QQ 在线咨询 -->
        <a
          :href="'http://wpa.qq.com/msgrd?v=3&site=qq&menu=yes&uin=' + systemConfig.CUSTOMER_SERVICE_QQ"
          target="_blank"
          rel="noopener noreferrer"
          class="text-center px-4 py-2 border-bottom-1 border-dashed"
        >
          <img src="@/assets/image/qq.png" alt="QQ在线咨询" title="QQ在线咨询" class="h-95px mx-auto mb-2" />
          QQ在线咨询
        </a>
        <!-- 下载 APP -->
        <a target="_blank" rel="noopener noreferrer" class="text-center px-4 py-2 border-bottom-1 border-dashed">
          <img :src="systemConfig.CUSTOMER_SERVICE_WX_IMG" alt="下载APP" title="下载APP" class="h-95px mx-auto mb-2" />
          微信咨询
        </a>
        <!-- 关闭 -->
        <div class="text-center px-4 py-2 text-gray-500" @click="showSidebar = false">关闭</div>
      </div>

      <div v-if="isMobile && showSidebar" class="fixed flex-col right-0 top-50 bg-gray-50 text-blue-400 text-12px flex flex-col" 
        style="background-color: lightgray; align-items: center; border-radius: 3px; padding: 8px 4px;"
        @click="showCustomerDialog">
        <img src="@/assets/image/qq-icon.png" alt="QQ在线咨询" title="QQ在线咨询" style="width: 20px; height: 20px;" />
        <span style="writing-mode: vertical-rl; color: black; font-size: 15px; font-weight: bold;">在线咨询</span>
      </div>
    </div>

    <el-dialog title="联系客服" :visible.sync="dialogVisible" @close="dialogVisible" width="80%" center>
      <div class="flex-row" style="align-items: center; ">
        <span>QQ：{{systemConfig.CUSTOMER_SERVICE_QQ}}</span>
        <el-button round size="small" type="success" @click="copy(systemConfig.CUSTOMER_SERVICE_QQ)" style="margin-left: 30px;">复制</el-button>
      </div>
      <div class="flex-row" style="align-items: center; justify-content: center; margin-top: 10px;">
        <span>微信：{{systemConfig.CUSTOMER_SERVICE_WX}}</span>
        <el-button round size="small" type="success" @click="copy(systemConfig.CUSTOMER_SERVICE_WX)" style="margin-left: 30px;">复制</el-button>
      </div>
      <div class="flex-row" style="align-items: center; justify-content: center; margin-top: 10px; color: red; font-size: 15px;">
        悟空给的佣金高过大多同类平台，所以请珍惜认真按要求做任务，不然可能禁号
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { register, regionList, sendCode } from "@/api/user";

export default {
  data() {
    return {
      showSidebar: true,
      isMobile: false,
      dialogVisible: false,
      smsSent: false,
      smsSendTimer: 60,
      userRuleCheck: false,
      formData: {
        userRole: "BUYER",//BUYER,SELLER
        username: "",
        password: "",
        password2: "",
        name: "",
        address: "",
        email: "",
        mobile: "",
        mobileCode: "",
        qq: "",
        wechat: "",
        pid: "",
      },
      formRules: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
        password2: [
          {
            required: true,
            message: "请再次输入密码",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入真实姓名",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "请选择所在地区",
            trigger: "blur",
          },
        ],
        // email: [
        //   {
        //     required: true,
        //     message: "请输入电子邮箱",
        //     trigger: "blur",
        //   },
        // ],
        mobile: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "blur",
          },
        ],
        mobileCode: [
          {
            required: true,
            message: "请输入手机验证码",
            trigger: "blur",
          },
        ],
        qq: [{ required: true, message: "请输入QQ号", trigger: "blur" }],
        wechat: [
          {
            required: true,
            message: "请输入微信号",
            trigger: "blur",
          },
        ],
      },
      regionOptions: [],
    };
  },
  computed: {
    systemConfig() {
      return this.$store.state.config.config;
    },
  },
  async mounted() {
    console.log('mounted')
    // 优先使用传入的pid，然后是本地缓存的pid
    var pid = this.$route.query.pid
    if (pid != undefined && pid != '') {
      console.log('register page pid ' + pid)
      this.formData.pid = pid
      localStorage.setItem('pid', pid)
    } else {
      var cachePid = localStorage.getItem('pid')
      if (cachePid != undefined && cachePid != '') {
        this.formData.pid = cachePid
        console.log('use local pid ' + this.formData.pid)
      }  
    }

    this.isMobile = document.documentElement.clientWidth <= 520;

    const { data: regions } = await regionList();
    this.regionOptions = regions;
  },
  methods: {
    showCustomerDialog() {
      this.dialogVisible = true
    },
    copy(str) {
      this.$copyText(str).then(
        () => {
          this.$message.success("已复制");
        },
        () => {
          this.$message.error("复制失败");
        }
      );
    },
    // 表单提交
    formSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.userRuleCheck === false) {
            this.$message.error("请先阅读并同意用户协议");
            return;
          }
          const data = this.formObjMapper(this.formData);
          await register(data);
          this.$message.success("注册成功");
          this.$router.push("/login");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    formObjMapper(source) {
      const mapping = {
        userRole: "userRole",
        loginName: "username",
        password: "password",
        phone: "mobile",
        checkCode: "mobileCode",
        realName: "name",
        sex: "FEMALE",
        // email: "email",
        qq: "qq",
        wxAccount: "wechat",
        pid: "pid",
      };

      const target = {};
      for (const [key, value] of Object.entries(mapping)) {
        if (value === undefined) {
          // 如果 value 为 undefined 则直接使用 key 作为属性名
          target[key] = source[key];
        } else if (value === "NULL") {
          // 如果 value 是 "NULL" 则使用 null 作为属性值
          target[key] = null;
        } else {
          // 否则将属性值从 source 中提取出来，并使用 key 作为属性名，放入目标对象中
          target[key] = source[value];
        }
      }

      target.provinceCode = source.address[0];
      target.cityCode = source.address[1];
      target.districtCode = source.address[2];

      return target;
    },
    // 地区选择
    regionChange() {
      console.log("a");
    },
    // 发送验证码
    async sendSms() {
      if (this.formData.mobile === "") {
        this.$message.error("请输入手机号码");
        return;
      }
      await sendCode(this.formData.mobile);
      this.$message.success("验证码已发送");
      this.smsSent = true;
      this.smsSendTimer = 60;
      let timer = setInterval(() => {
        if (this.smsSendTimer == 1) {
          clearInterval(timer);
          this.smsSent = false;
        } else {
          this.smsSendTimer -= 1;
        }
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>
.mb-2rem {
  margin-bottom: 2rem;
}
.plr-2rem {
  padding: 0 2rem;
}

.login-box {
  width: 700px;
  padding: 2rem;
  border-radius: 7px;
  background-color: #ffffff;

  .logo {
    text-align: center;
    .mb-2rem;
    img {
      width: 300px;
      height: auto;
      display: inline;
    }
  }
  .form {
    .plr-2rem;
    .form-title {
      padding-bottom: 0.5rem;
      margin: 2rem 0;
      border-bottom: 1px dashed #ccc;
    }
    /deep/ .el-form-item {
      margin-bottom: 0.2rem;
    }
    .form-input {
      width: 300px;
    }
    .form-input-sms {
      width: 200px;
    }

    .form-input-sms-btn {
      width: 90px;
      margin-left: 10px;
    }
    .form-btn {
      width: 300px;
    }
  }
  .pc-action {
    display: flex;
    justify-content: space-between;
    .plr-2rem;
    a {
      color: inherit;
      text-decoration: none;
    }
  }
  .mob-action {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  .plr-2rem {
    padding: 0 1rem;
  }
  .login-box {
    width: 100vw;
    .logo {
      image {
        width: 100%;
      }
    }
    .form {
      padding: 0 1rem;
      form-title {
        padding-bottom: 0.5rem;
        margin: 2rem 0;
      }
      /deep/ .el-form-item {
        margin-bottom: 0.2rem;
      }
      .form-input {
        width: 100%;
      }
      .form-input-sms {
        width: 100%;
        margin-bottom: 0.5rem;
      }

      .form-input-sms-btn {
        display: block;
        width: 100%;
        margin-left: 0;
        margin-bottom: 0.3rem;
      }
      .form-btn {
        width: 100%;
      }
    }
    .pc-action {
      display: none;
    }
    .mob-action {
      padding: 0 1rem;
      display: block;
      .action-item {
        margin-bottom: 0.5rem;
        a {
          color: inherit;
          text-decoration: none;
        }
      }
    }
  }
}
</style>
